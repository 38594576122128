import styled from 'styled-components'

interface ContainerProps {
  $loading: boolean
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: auto;

  ${(props) => (props.$loading ? 'pointer-events: none; opacity: 0.5;' : '')}
`

interface ToggleProps {
  hidden: boolean
}

export const Toggle = styled.div<ToggleProps>`
  width: 90%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;

  span {
    margin-top: 5px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }

  img {
    transform: rotate(${(props) => (props.hidden ? '270deg' : '0deg')});
    margin-right: 3px;
  }
`

export const CommentContainer = styled.div`
  width: 90%;
  background-color: #1e2b34;
  border-radius: 5px;
  border: solid 0.5px #1e2b34;
  margin-top: 5px;

  p {
    color: #ffffff;
    margin: 3px;
    font-size: 14px;
  }

  .attachments {
    img {
      width: 95%;
      margin-left: 2.5%;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

export const InputContainer = styled.div`
  width: 90%;

  textarea {
    width: 100%;
    resize: none;
    min-height: 100px;
    border: solid 0.5px transparent;
    background-color: transparent;
    overflow: hidden;
  }

  .textarea-container {
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 0.5px #ffffff;
    margin-top: 10px;

    img {
      width: 95%;
      margin-left: 2.5%;
      margin-bottom: 5px;
    }

    .image-wrapper {
      position: relative;
    }

    .icon {
      position: absolute;
      top: 2px;
      right: 2px;
      width: auto;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }
`

export const DragAndDropContainer = styled.span`
  width: 100%;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #797979;
  border-radius: 5px;
  border: solid 0.5px #ffffff;
  min-height: 100px;
  background-color: #ffffff;
`

export const SaveButton = styled.button`
  background-color: #ffffff;
  width: 25%;
  font-size: 14px;
  border-radius: 5px;
  border: solid 0.5px #ffffff;
`

export const CancelButton = styled.span`
  font-size: 14px;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
`

export const ImageButton = styled.img`
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
`
