import React, { ReactElement } from 'react'
import ReviewPageSkeleton from '../../skeletons/ReviewPage'

interface Props {
  children: ReactElement
  isLoading: boolean
}

export default function Loading({ children, isLoading }: Props) {
  return isLoading ? <ReviewPageSkeleton /> : children
}
