import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  --offset: 0px;
  grid-template-columns: 40px calc(24% + var(--offset)) auto 18% 40px;
  height: 100vh;
  background-image: linear-gradient(to bottom, #e9eaeb, #e4e5e7);
  background-color: #eee;
  --poster-color: transparent;
  overflow: hidden hidden;
`

export interface ViewerProps {
  rightBar: boolean
  leftBar: boolean
}

export const ViewerContainer = styled.div<ViewerProps>`
  display: grid;
  grid-column-start: ${(props) => (props.leftBar ? 3 : 2)};
  grid-column-end: ${(props) => (props.rightBar ? 4 : 5)};
`

export interface SidebarProps {
  expanded: boolean
}

export const RightBar = styled.div<SidebarProps>`
  height: 100%;
  display: grid;
  grid-column-start: ${(props) => (props.expanded ? 4 : 5)};
  grid-column-end: 6;
  grid-template-columns: 40px auto;
  gap: 0rem;
  background-color: #3b4e5c;
  transition: 200ms;
`

export const CommentsSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3b4e5c;
  justify-content: space-between;
  padding-bottom: 30px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 10%;
`

export const LeftBar = styled.div<SidebarProps>`
  display: grid;
  grid-column-start: 1;
  grid-column-end: ${(props) => (props.expanded ? 3 : 2)};
  grid-template-columns: ${(props) => (props.expanded ? 'auto 40px' : '40px')};
  grid-template-rows: 100%;
  gap: 0rem;
  background-color: #3b4e5c;
  transition: 200ms;
`

interface ExpandBarProps {
  resizable: boolean
}

export const ExpandBar = styled.div<ExpandBarProps>`
  display: grid;
  grid-template-rows: 2.3% auto;
  background-color: #1e2b34;
  cursor: ${(props) => (props.resizable ? 'col-resize' : 'pointer')};
  transition: 200ms;
`

export const Icon = styled.img`
  grid-row-start: 2;
  width: 50%;
  justify-self: center;
  cursor: pointer;
`
