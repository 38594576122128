import React, { useState, MouseEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  ViewerContainer,
  RightBar,
  CommentsSection,
  LeftBar,
  Icon,
  ExpandBar,
  ButtonContainer
} from './styles'

import { ProductProvider } from '../../contexts/ProductContext'
import { VersionProvider } from '../../contexts/VersionContext'
import Loading from '../../components/Loading'
import ProductLoading from '../../components/ProductLoading'
import AssetViewer from '../../components/AssetViewer'
import VersionSelector from '../../components/VersionSelector'
import ApprovalButton from '../../components/ApprovalButton'
import References from '../../components/References'

import openReference from '../../assets/icons/reference-sidebar-open.png'
import closeReference from '../../assets/icons/reference-sidebar-close.png'
import openComment from '../../assets/icons/comment-sidebar-open.png'
import closeComment from '../../assets/icons/comment-sidebar-close.png'

interface ParamTypes {
  id: string
}

const Review: React.FC = () => {
  const { ready } = useTranslation(['review'])
  const [rightBarExpanded, setRightBarExpanded] = useState(true)
  const [leftBarExpanded, setLeftBarExpanded] = useState(true)

  const dragging = useRef(false)
  const initialX = useRef(0)
  const minOffset = -0.1 * document.documentElement.clientWidth
  const maxOffset = 0.2 * document.documentElement.clientWidth

  const mouseDown = (e: MouseEvent<HTMLDivElement>) => {
    dragging.current = true
    if (!initialX.current) initialX.current = e.pageX
  }

  const mouseUp = (e: MouseEvent<HTMLDivElement>) => {
    dragging.current = false
  }

  const mouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging.current) return
    const newOffset = e.pageX - initialX.current
    if (newOffset < maxOffset && newOffset > minOffset) {
      const grid = document.getElementById('grid')
      if (!grid) return
      grid.style.setProperty('--offset', `${newOffset}px`)
    }
  }

  const toggleRightBar = () => setRightBarExpanded(!rightBarExpanded)
  const toggleLeftBar = () => setLeftBarExpanded(!leftBarExpanded)

  const params = useParams<ParamTypes>()

  return (
    <ProductProvider {...params}>
      <Loading isLoading={!ready}>
        <ProductLoading>
          <Container id='grid' onMouseMove={mouseMove} onMouseUp={mouseUp}>
            <LeftBar expanded={leftBarExpanded}>
              {leftBarExpanded && <References />}
              <ExpandBar onMouseDown={mouseDown} resizable>
                <Icon
                  onClick={toggleLeftBar}
                  src={leftBarExpanded ? closeReference : openReference}
                  alt='Open references'
                />
              </ExpandBar>
            </LeftBar>
            <VersionProvider>
              <ViewerContainer leftBar={leftBarExpanded} rightBar={rightBarExpanded}>
                <AssetViewer />
              </ViewerContainer>
              <RightBar expanded={rightBarExpanded}>
                <ExpandBar onClick={toggleRightBar} onKeyDown={toggleRightBar} resizable={false}>
                  <Icon src={rightBarExpanded ? closeComment : openComment} alt='Open comments' />
                </ExpandBar>
                {rightBarExpanded && (
                  <CommentsSection>
                    <VersionSelector />
                    <ButtonContainer>
                      <ApprovalButton mode='approve' />
                      <ApprovalButton mode='reject' />
                      <ApprovalButton mode='review' />
                    </ButtonContainer>
                  </CommentsSection>
                )}
              </RightBar>
            </VersionProvider>
          </Container>
        </ProductLoading>
      </Loading>
      <ToastContainer
        className='toast'
        toastClassName='toast-review'
        position='bottom-left'
        autoClose={5000}
        hideProgressBar
        closeOnClick={false}
      />
    </ProductProvider>
  )
}

export default Review
