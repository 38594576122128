import styled from 'styled-components'

export const TextStyleBold = styled.span`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;

  @media (max-width: 800px) {
    display: none;
  }
`

export const Arrow = styled.div`
  cursor: pointer;
  font-weight: 900;
  min-height: 30px;
  max-height: 5em;

  a {
    text-decoration: inherit;
    font-size: 30px;
    color: #1e2b34;
    text-shadow: 0px 1px #3b4e5c;
  }
`

export const TextStyleLight = styled.p`
  font-size: 16px !important;
  font-weight: normal;
  text-align: center;
  color: #fff;
  padding: 5px 10px 4px 10.2px;
`

export const TextStyleSmall = styled.span`
  margin: 0 0 0px 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
  text-align: left;
  color: #fff;

  @media (max-width: 800px) {
    display: none;
  }

  @media (max-width: 1150px) and (min-width: 800px) {
    font-size: 10px;
  }
`

export const InfoBar = styled.div`
  min-height: 30px;
  max-height: 5em;
  background-color: #1f2f3b;
  border-radius: 20px;
  min-width: 55px;

  @media (max-width: 800px) {
    cursor: pointer;
  }
`
interface HiddenAssetNameProps {
  AssetNameVisible: boolean
}

export const HiddenAssetName = styled.p<HiddenAssetNameProps>`
  display: none;

  @media (max-width: 800px) {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: black;
    z-index: 2;
    display: ${(props) => (props.AssetNameVisible ? 'block' : 'none')};
  }
`

export const TryItBar = styled.button`
  width: fit-content;
  height: 30px;
  padding: 10px 10px 10px 10.2px;
  border-radius: 17px;
  background-color: #1f2f3b;
  display: flex;
  align-items: center;
`

export const CarouselBar = styled.div`
  width: 30%;
  min-width: fit-content;
  height: 30px;
  padding: 10px 10px 10px 10.2px;
  border-radius: 20px;
  background-color: #1f2f3b;
  display: flex;
  align-items: center;
  font-size: 1.4em;
  justify-content: space-between;

  span {
    cursor: pointer;
    color: #fff !important;
    height: 1.4em !important;
    font-family: inherit;
  }
`
export const ZoomBar = styled.div`
  width: 15%;
  height: 30px;
  padding: 6px 10px 6px;
  border-radius: 20px;
  background-color: #1f2f3b;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Icon3D = styled.div`
  content: url(/icons/3d-icon.png);
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1em;
  z-index: 2;
`

export const IconZoomIn = styled.div`
  content: url(/icons/zoom-in.png);
  color: #fff;
  height: 1.4em;
`

export const IconZoomOut = styled.div`
  content: url(/icons/zoom-out.png);
  color: #fff;
  height: 1.4em;
`
interface CarouselBarProps {
  ListIndex: number
  AssetIndex: number
}

export const Fig = styled.div<CarouselBarProps>`
  cursor: pointer;
  width: 10px;
  height: 10px;
  border: solid 1px #ffffff;
  border-radius: 50%;

  ${(props) =>
    props.ListIndex === props.AssetIndex ? 'background-color: #fff' : 'background-color: unset'};
`

interface BodyProps {
  QRCodeVisible: boolean
}

export const Body = styled.div<BodyProps>`
  display: flex;
  justify-content: center;

  model-viewer {
    width: 100%;
    height: 100%;
  }

  .grid {
    width: 100%;
    max-height: 100vh;
    display: grid;

    grid-template-areas:
      'revision    revision    revision    revision    revision'
      'prevArrow   infoBar     infoBar     infoBar     nextArrow'
      'assetViewer assetViewer assetViewer assetViewer assetViewer'
      'qrCode      qrCode      NA          NA          NA'
      'tryItBar    tryItBar    carouselBar zoomBar     zoomBar';

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: hidden;

      img {
        max-width: 95%;
        max-height: 100%;
      }
    }
    .card:nth-child(1) {
      grid-area: revision;
    }
    .card:nth-child(2) {
      grid-area: prevArrow;
    }
    .card:nth-child(3) {
      grid-area: infoBar;
    }
    .card:nth-child(4) {
      grid-area: nextArrow;
    }
    .card:nth-child(5) {
      grid-area: assetViewer;
    }
    .card:nth-child(6) {
      grid-area: tryItBar;
    }
    .card:nth-child(7) {
      grid-area: qrCode;
      overflow: visible;
      justify-content: flex-end !important;
      align-items: center !important;
    }
    .card:nth-child(8) {
      grid-area: carouselBar;
    }
    .card:nth-child(9) {
      grid-area: zoomBar;
    }

    grid-template-rows: 1% 9% 79% 1% 5%;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));

    .qrcode {
      z-index: 2;
      visibility: ${(props) => (props.QRCodeVisible ? 'visible' : 'hidden')};

      canvas {
        margin: 10px;
      }
    }

    .alert {
      font-size: 14px;
      color: red;
    }
  }
`
