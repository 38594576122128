import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Button } from './styles'

interface Props {
  text: string
  onUndo: () => void
  closeToast: () => void
}

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  const unloadEvent = event
  unloadEvent.returnValue = 'Are you sure you want to exit?'
}

const Undo: React.FC<Props> = ({ text, onUndo, closeToast }: Props) => {
  const { t } = useTranslation(['undo'])

  const handleClick = () => {
    onUndo()
    closeToast()
  }

  return (
    <div>
      <span>
        {text} <Button onClick={handleClick}>{t('undo')}</Button>
      </span>
    </div>
  )
}

const useUndoToast = () => {
  const undo = useRef(false)

  const openToast = (text: string, callback: () => void) => {
    undo.current = false
    const toastId = toast(
      <Undo
        text={text}
        onUndo={() => {
          undo.current = true
        }}
        closeToast={() => toast.dismiss(toastId)}
      />,
      {
        onOpen: () => {
          window.addEventListener('beforeunload', beforeUnloadListener, { capture: true })
        },
        onClose: () => {
          window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
          if (!undo.current) return callback()
          return null
        }
      }
    )
  }

  return openToast
}

export default useUndoToast
