import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import HttpApi from 'i18next-http-backend'

const lng = new URLSearchParams(window.location.search).get('lng') === 'en-US' ? 'en-US' : 'pt-BR'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: false,
    react: {
      useSuspense: false
    },
    lng,
    fallbackLng: false,
    whitelist: ['pt-BR', 'en-US'],
    interpolation: {
      escapeValue: false
    },
    defaultNS: 'landing-page:index',
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          enabled: process.env.NODE_ENV !== 'development',
          expirationTime: 24 * 60 * 60 * 1000
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          allowMultiloading: true
        }
      ]
    }
  })

i18n.services.pluralResolver.addRule('pt-BR', {
  name: 'pt-BR',
  numbers: [1, 2],
  plurals(n: number) {
    return Number(n !== 1)
  }
})

export function setLanguage(language: 'pt-BR' | 'en-US'): void {
  i18n.changeLanguage(language)
}

export function getCurrentLng(): 'pt-BR' | 'en-US' {
  return i18n.language || window.localStorage.i18nextLng || lng
}

export default i18n
