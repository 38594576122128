export const breakPoints = {
  xs: '576px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px'
}

export const maxXs = `max-width: ${breakPoints.xs}`
export const maxSm = `max-width: ${breakPoints.sm}`
export const maxMd = `max-width: ${breakPoints.md}`
export const maxLg = `max-width: ${breakPoints.lg}`
export const maxXl = `max-width: ${breakPoints.xl}`

export const minXs = `min-width: ${breakPoints.xs}`
export const minSm = `min-width: ${breakPoints.sm}`
export const minMd = `min-width: ${breakPoints.md}`
export const minLg = `min-width: ${breakPoints.lg}`
export const minXl = `min-width: ${breakPoints.xl}`
