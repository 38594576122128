import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import './config/i18n'

import Routes from './routes'

import GlobalStyle from './styles/global'

function App() {
  return (
    <Router>
      <Routes />
      <GlobalStyle />
    </Router>
  )
}

export default App
