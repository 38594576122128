import React, { useState, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { VersionContext } from '../../contexts/VersionContext'
import useUndoToast from '../../hooks/useUndoToast'
import ImageLink from '../ImageLink'
import * as API from '../../services/api'
import InputComponent from './InputComponent'
import { Container, Toggle, CommentContainer, ImageButton } from './styles'
import toggleIcon from '../../assets/icons/toggle.svg'
import editIcon from '../../assets/icons/edit.svg'
import deleteIcon from '../../assets/icons/trash-can.png'

const Comments: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { assets, assetIndex } = useContext(VersionContext)
  const [comments, setComments] = useState<API.Comment[]>([])

  const [hidden, setHidden] = useState(false)
  const toggleHidden = () => setHidden(!hidden)

  const [loading, setLoading] = useState(false)

  const mounted = useRef(true)

  useEffect(() => {
    const cleanup = () => {
      mounted.current = false
    }
    mounted.current = true
    if (!assets[assetIndex]) return cleanup

    assets[assetIndex].Comments.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
    if (mounted.current) setComments(assets[assetIndex].Comments)

    return cleanup
  }, [assets, assetIndex])

  const createComment = (content: string, attachments: string[]) => {
    if (!assets[assetIndex]) return

    setLoading(true)
    API.createComment(assets[assetIndex].id, { content, attachments }).then((c) => {
      if (mounted.current) {
        if (c) setComments([...comments, c])
        setLoading(false)
      }
    })
  }

  const updateComment = (id: string, content: string, attachments: string[]) => {
    if (!assets[assetIndex]) return

    setLoading(true)
    API.updateComment(id, { content, attachments }).then((c) => {
      if (mounted.current) {
        if (c) setComments(comments.map((prev) => (prev.id === c.id ? c : prev)))
        setLoading(false)
      }
    })
  }

  const openToast = useUndoToast()

  const deleteComment = (id: string) => {
    openToast(t('comments.deleted'), () => {
      setLoading(true)
      API.deleteComment(id).then((c) => {
        if (mounted.current) {
          if (c) setComments(comments.filter((e) => e.id !== c.id))
          setLoading(false)
        }
      })
    })
  }

  const [editArray, setEditArray] = useState<string[]>([])
  const setAsEditing = (id: string) => {
    setEditArray([...editArray, id])
  }
  const setAsNotEditing = (id: string) => {
    setEditArray(editArray.filter((c) => c !== id))
  }
  const isEditing = (id: string) => {
    return editArray.includes(id)
  }

  if (!assets[assetIndex]) return null

  return (
    <Container $loading={loading}>
      <Toggle hidden={hidden} onClick={toggleHidden}>
        <span>{t('comments.title')}</span>
        <img src={toggleIcon} alt='' />
      </Toggle>
      {!hidden ? (
        <div>
          <>
            {comments.map((c) =>
              isEditing(c.id) ? (
                <InputComponent
                  key={c.id}
                  initialInput={c.content}
                  initialAttachments={c.attachments}
                  cancelCallback={() => setAsNotEditing(c.id)}
                  saveCallback={(input: string, attachments: string[]) => {
                    updateComment(c.id, input, attachments)
                    setAsNotEditing(c.id)
                  }}
                />
              ) : (
                <CommentContainer key={c.id}>
                  <p>{c.content}</p>
                  <div className='attachments'>
                    {c.attachments.map((url) => (
                      <ImageLink src={url} key={url} />
                    ))}
                  </div>
                  <div className='button-container'>
                    <ImageButton onClick={() => setAsEditing(c.id)} src={editIcon} />
                    <ImageButton onClick={() => deleteComment(c.id)} src={deleteIcon} />
                  </div>
                </CommentContainer>
              )
            )}
          </>
          <InputComponent saveCallback={createComment} />
        </div>
      ) : null}
    </Container>
  )
}

export default Comments
