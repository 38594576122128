import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { Product, ProductNeighbours, fetchProduct, fetchProductNeighbours } from '../services/api'

interface Props {
  children: ReactNode
  id: string
}

interface ProductContextData {
  neighbours: ProductNeighbours | null
  product: Product | null
  loading: boolean
}

export const ProductContext = createContext<ProductContextData>({} as ProductContextData)

export const ProductProvider: React.FC<Props> = ({ children, id }: Props) => {
  const [product, setProduct] = useState<Product | null>(null)
  const [neighbours, setProductNeighbours] = useState<ProductNeighbours | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetchProduct(id).then((p) => {
      setProduct(p)
      setLoading(false)
    })
    fetchProductNeighbours(id).then((p) => {
      setProductNeighbours(p)
    })
  }, [id])

  return (
    <ProductContext.Provider
      value={{
        neighbours,
        product,
        loading
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
