import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import ImageLink from '../ImageLink'
import { upload } from '../../services/upload'
import {
  InputContainer,
  DragAndDropContainer,
  CancelButton,
  SaveButton,
  ImageButton
} from './styles'
import attachment from '../../assets/icons/attachment.svg'
import removeIcon from '../../assets/icons/close.svg'

interface Props {
  initialInput?: string
  initialAttachments?: string[]
  cancelCallback?: () => void
  saveCallback: (input: string, attachments: string[]) => void
}

const Input: React.FC<Props> = ({
  initialInput,
  initialAttachments,
  cancelCallback,
  saveCallback
}: Props) => {
  const { t } = useTranslation(['review'])
  const [input, setInput] = useState(initialInput || '')
  const [attachments, setAttachments] = useState<string[]>(initialAttachments || [])
  const clearInput = () => {
    setInput(initialInput || '')
    setAttachments(initialAttachments || [])
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      upload(file, (e: string) => {
        setAttachments((a) => [...a, e])
      })
    })
  }, [])

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true
  })

  const updateTextAreaHeight = (textarea: HTMLTextAreaElement) => {
    // This is needed becausae the height is considered in the scrollHeight: without this line
    // the textarea continuously grow as the user type instead of only growing when needed
    // eslint-disable-next-line no-param-reassign
    textarea.style.height = 'auto'
    // eslint-disable-next-line no-param-reassign
    textarea.style.height = `${2 + textarea.scrollHeight}px`
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateTextAreaHeight(e.target)
    setInput(e.target.value)
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (e.clipboardData.files.length) {
      onDrop(Array.from(e.clipboardData.files))
      e.preventDefault()
    }
  }

  useEffect(() => {
    const textarea = document.querySelector('textarea')
    if (textarea) updateTextAreaHeight(textarea)
  }, [])

  const removeAttachment = (url: string) => {
    setAttachments(attachments.filter((e) => e !== url))
  }

  return (
    <InputContainer {...getRootProps()}>
      <input {...getInputProps()} />
      <div className='textarea-container'>
        {!isDragActive ? (
          <textarea
            placeholder={t('comments.placeholder')}
            value={input}
            onChange={handleChange}
            onPaste={handlePaste}
          />
        ) : (
          <DragAndDropContainer>{t('comments.drop')}</DragAndDropContainer>
        )}
        {attachments.map((url) => (
          <div className='image-wrapper' key={url}>
            <ImageButton className='icon' onClick={() => removeAttachment(url)} src={removeIcon} />
            <ImageLink src={url} key={url} />
          </div>
        ))}
      </div>
      <div className='button-container'>
        <ImageButton onClick={open} onKeyPress={open} src={attachment} />
        <CancelButton
          onClick={() => {
            clearInput()
            if (cancelCallback) cancelCallback()
          }}
        >
          {t('comments.cancel')}
        </CancelButton>
        <SaveButton
          onClick={() => {
            saveCallback(input, attachments)
            clearInput()
          }}
        >
          {t('comments.save')}
        </SaveButton>
      </div>
    </InputContainer>
  )
}

export default Input
