import React from 'react'

import { Route, Switch } from 'react-router-dom'
import Review from '../pages/Review'

import LandingPage from '../pages/LandingPage'

function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={LandingPage} />
      <Route path='/review/:id' component={Review} />
      <Route path='*' component={LandingPage} />
    </Switch>
  )
}

export default Routes
