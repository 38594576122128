import React from 'react'
import { LeftBar, ExpandBar, Grid, Gallery, MainImage, Image } from './styles'

const LeftBarSkeleton: React.FC = () => {
  return (
    <LeftBar>
      <Grid>
        <MainImage />
        <Gallery>
          <Image />
          <Image />
        </Gallery>
      </Grid>
      <ExpandBar />
    </LeftBar>
  )
}

export default LeftBarSkeleton
