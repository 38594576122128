import axios from 'axios'

import config from '../config/application.json'

export enum AssetType {
  GLB = 'GLB',
  USDZ = 'USDZ',
  RENDER = 'RENDER'
}

export enum AssetStatus {
  WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
  UNDER_REVIEW = 'UNDER_REVIEW',
  TEAM_APPROVED = 'TEAM_APPROVED',
  CUSTOMER_APPROVED = 'CUSTOMER_APPROVED',
  TEAM_REJECTED = 'TEAM_REJECTED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED'
}

export enum ReferenceType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export interface Asset {
  id: string
  type: AssetType
  url: string
  status: AssetStatus
  size?: string
  Comments: Comment[]
}

export interface Version {
  id: string
  name: string
  createdAt: string
  Assets: Asset[]
}

export interface Reference {
  id: string
  name: string
  type: ReferenceType
  url: string
  description: string
  createdAt: string
}

export interface Product {
  id: string
  name: string
  customerId: string
  sku: string
  internal: boolean
  Versions: Version[]
  References: Reference[]
}

export interface Comment {
  id: string
  content: string
  attachments: string[]
  createdAt: string
}

export interface ProductNeighbours {
  prev?: Neighbours
  next?: Neighbours
}

type Neighbours = Pick<Product, 'id'>

const baseURL =
  process.env.REACT_APP_STAGE === 'production'
    ? config.production.API_URL
    : process.env.REACT_APP_STAGE === 'dev'
    ? config.dev.API_URL
    : config.local.API_URL

const api = axios.create({ baseURL })

const getAssetSize = async (version: Version) => {
  const asset = version.Assets.find((e: Asset) => e.type === 'USDZ')
  if (asset) {
    const {
      headers: { 'content-length': contentLength }
    } = await axios.head(asset.url)
    const bytes = Number(contentLength)
    asset.size = `${(bytes / 1024 / 1024).toFixed(2)} MB`
  }
}

export const fetchProduct = async (id: string): Promise<Product | null> => {
  try {
    const { data: product } = await api.get<Product>(`/v1/products/${id}`)
    product.Versions.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    product.Versions = product.Versions.filter((version, index) => {
      const underReview = version.Assets.some(
        (e) => e.status === 'UNDER_REVIEW' || e.status === 'WORK_IN_PROGRESS'
      )
      const hasComments = version.Assets.some((e) => e.Comments.length)
      return (underReview && index === 0) || !underReview || hasComments
    })
    await Promise.all(product.Versions.map(getAssetSize))
    return product
  } catch (e) {
    return null
  }
}

export const fetchProductNeighbours = async (id: string): Promise<ProductNeighbours | null> => {
  try {
    const { data: productNeighbours } = await api.get<ProductNeighbours>(
      `/v1/products/${id}/neighbours`
    )
    return productNeighbours
  } catch (e) {
    return null
  }
}

export const updateAsset = async (data: Partial<Asset>): Promise<Asset | null> => {
  try {
    const { data: asset } = await api.patch<Asset>(`/v1/assets/${data.id}`, data)
    return asset
  } catch (e) {
    return null
  }
}

export const createComment = async (
  assetId: string,
  data: Partial<Comment>
): Promise<Comment | null> => {
  try {
    const { data: comment } = await api.post<Comment>(`/v1/assets/${assetId}/comments`, data)
    return comment
  } catch (e) {
    return null
  }
}

export const getComments = async (assetId: string): Promise<Comment[] | null> => {
  try {
    const { data: comments } = await api.get<Comment[]>(`/v1/assets/${assetId}/comments`)
    return comments.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
  } catch (e) {
    return null
  }
}

export const deleteComment = async (commentId: string): Promise<Comment | null> => {
  try {
    const { data: comment } = await api.delete<Comment>(`/v1/comments/${commentId}`)
    return comment
  } catch (e) {
    return null
  }
}

export const updateComment = async (
  commentId: string,
  data: Partial<Comment>
): Promise<Comment | null> => {
  try {
    const { data: comment } = await api.patch<Comment>(`/v1/comments/${commentId}`, data)
    return comment
  } catch (e) {
    return null
  }
}

export const getUploadUrl = async (extension?: string): Promise<string | null> => {
  try {
    let url = '/v1/upload'
    if (extension) url += `?extension=${extension}`
    const { data: uploadUrl } = await api.get<string>(url)
    return uploadUrl
  } catch (e) {
    return null
  }
}
