import React, { createContext, ReactNode, useState, useEffect, useContext } from 'react'

import { ProductContext } from './ProductContext'
import { Asset } from '../services/api'

interface Props {
  children: ReactNode
}

interface VersionContextData {
  versionIndex: number
  setVersionIndex: (n: number) => void
  assetIndex: number
  setAssetIndex: (n: number) => void
  assets: Asset[]
}

export const VersionContext = createContext<VersionContextData>({} as VersionContextData)

export const VersionProvider: React.FC<Props> = ({ children }: Props) => {
  const { product } = useContext(ProductContext)
  const [versionIndex, setVersionIndex] = useState(0)
  const [assetIndex, setAssetIndex] = useState(0)

  const assets =
    product?.Versions[versionIndex].Assets?.filter((e: Asset) => e.type !== 'USDZ') || []

  useEffect(() => {
    setAssetIndex(0)
  }, [versionIndex])

  return (
    <VersionContext.Provider
      value={{
        versionIndex,
        setVersionIndex,
        assetIndex,
        setAssetIndex,
        assets
      }}
    >
      {children}
    </VersionContext.Provider>
  )
}
