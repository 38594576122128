import React, { useContext, useEffect, useState } from 'react'
import { Grid, Gallery, Image, MainImage } from './styles'
import { ProductContext } from '../../contexts/ProductContext'
import { ReferenceType } from '../../services/api'

const References: React.FC = () => {
  const { product } = useContext(ProductContext)
  const [mainImage, setMainImage] = useState<string>('')

  useEffect(() => {
    setMainImage(
      product?.References?.filter((reference) => reference.type === ReferenceType.IMAGE)[0]?.url ||
        ''
    )
  }, [product])

  const images = product?.References?.filter(
    (reference) => reference.type === ReferenceType.IMAGE
  )?.map((reference) => {
    return (
      <Image
        key={reference.id}
        src={reference.url}
        onClick={() => setMainImage(reference.url || '')}
      />
    )
  })

  return (
    <Grid>
      <MainImage src={mainImage} />
      <Gallery>{images}</Gallery>
    </Grid>
  )
}

export default References
