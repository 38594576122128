import { getUploadUrl } from './api'

export const upload = async (file: File, onEnd: (s: string) => void) => {
  let extension = file.name.split('.').pop()
  if (extension) extension = `.${extension}`
  const uploadUrl = await getUploadUrl(extension)
  if (!uploadUrl) return

  const xhr = new XMLHttpRequest()

  xhr.upload.addEventListener('load', () => {
    onEnd(uploadUrl.split('?')[0])
  })

  xhr.open('PUT', uploadUrl, true)
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  xhr.send(file)
}
