import styled from 'styled-components'

export const Grid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: max-content auto;
  column-gap: 20px;
  row-gap: 20px;
  overflow-y: auto;
`

export const Gallery = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 0.9fr));
  grid-auto-rows: 120px;
`

export const Image = styled.img`
  max-height: 120px;
  border-radius: 5px;
  place-self: center;
  cursor: pointer;
  background-color: #ffffff;
`

export const MainImage = styled.img`
  object-fit: contain;
  max-width: 650px;
  width: 90%;
  max-height: 650px;
  margin-top: 10px;
  border-radius: 5px;
  justify-self: center;
  align-self: end;
  background-color: #ffffff;
`
