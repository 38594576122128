import React, { ReactElement, useContext } from 'react'

import { ProductContext } from '../../contexts/ProductContext'
import Loading from '../Loading'

interface Props {
  children: ReactElement
}

const ProductLoading: React.FC<Props> = ({ children }: Props) => {
  const { loading } = useContext(ProductContext)

  return <Loading isLoading={loading}>{children}</Loading>
}

export default ProductLoading
