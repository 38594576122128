import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 5vh;
  margin-left: 5px;
  padding-top: 5px;
  overflow-y: auto;
  max-height: 75vh;
`

export const VersionContainer = styled.div`
  display: grid;
  grid-template-columns: 55px auto;
  grid-template-rows: 36px minmax(50px, max-content);
  align-items: center;
`

interface VersionNumberProps {
  selected: boolean
}

export const VersionNumber = styled.span<VersionNumberProps>`
  color: white;
  border-radius: 5px;
  border: solid 1px ${(props) => (props.selected ? '#ffffff' : 'transparent')};
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin-right: 20px;
  text-align: center;
  line-height: 1.6;
  cursor: pointer;
`

export const VersionText = styled.span`
  color: white;
  font-size: 16px;
  cursor: pointer;

  p {
    color: white;
    font-weight: 500;
    font-size: 14px;
  }
`

export const VerticalRule = styled.hr`
  background-color: white;
  height: calc(100% + 6px);
  width: 1px;
  border: 0px;
  margin-left: 15px;
`
