import React from 'react'

interface Props {
  src: string
}

const ImageLink: React.FC<Props> = ({ src }: Props) => (
  <a href={src} target='_blank' rel='noopener noreferrer'>
    <img src={src} alt='' />
  </a>
)

export default ImageLink
