import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from './styles'
import { ProductContext } from '../../contexts/ProductContext'
import { VersionContext } from '../../contexts/VersionContext'
import { updateAsset, AssetStatus, Asset } from '../../services/api'
import useUndoToast from '../../hooks/useUndoToast'

interface Props {
  mode: 'approve' | 'reject' | 'review'
}

const ApprovalButton: React.FC<Props> = ({ mode }: Props) => {
  const { t } = useTranslation(['review'])
  const { product } = useContext(ProductContext)
  const { versionIndex } = useContext(VersionContext)
  const openToast = useUndoToast()

  const patch = () => {
    openToast(
      mode === 'approve'
        ? t('approval.approved')
        : mode === 'reject'
        ? t('approval.rejected')
        : t('approval.reviewed'),
      () =>
        product?.Versions[versionIndex]?.Assets?.forEach((asset) =>
          updateAsset({
            id: asset.id,
            status:
              mode === 'approve'
                ? AssetStatus.TEAM_APPROVED
                : mode === 'reject'
                ? AssetStatus.TEAM_REJECTED
                : AssetStatus.UNDER_REVIEW
          })
        )
    )
  }

  const isWorkInProgress =
    product?.Versions[versionIndex].Assets?.some((e: Asset) => e.status === 'WORK_IN_PROGRESS') ||
    false

  if (isWorkInProgress)
    return mode === 'review' ? (
      <Button approve type='button' className='review-button' onClick={patch}>
        <span>{t('approval.review')}</span>
      </Button>
    ) : null

  return mode === 'approve' ? (
    <Button approve type='button' className='approval-button' onClick={patch}>
      <span>{t('approval.approve')}</span>
    </Button>
  ) : mode === 'reject' ? (
    <Button approve={false} type='button' className='approval-button' onClick={patch}>
      <span>{t('approval.reject')}</span>
    </Button>
  ) : null
}

export default ApprovalButton
