import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Body } from './styles'

import Loading from '../../components/Loading'

const LandingPage: React.FC = () => {
  const { t, ready } = useTranslation(['landing-page'])

  return (
    <Loading isLoading={!ready}>
      <Container>
        <Body>
          <h1>{t('headline.title')}</h1>
        </Body>
      </Container>
    </Loading>
  )
}
export default LandingPage
