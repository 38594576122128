import React from 'react'
import { Body, ViewerContainer, InfoBar, Arrow, TryItBar, TextStyleSmall, Icon3D } from './styles'

const CenterAreaSkeleton: React.FC = () => {
  return (
    <ViewerContainer>
      <Body>
        <div className='grid'>
          <div className='card' />
          <div className='card'>
            <Arrow>&#8249;</Arrow>
          </div>
          <div className='card'>
            <InfoBar />
          </div>
          <div className='card'>
            <Arrow>&#8250;</Arrow>
          </div>
          <div className='card' />
          <div className='card try'>
            <TryItBar>
              <Icon3D />
              <TextStyleSmall> Experimente na sua casa </TextStyleSmall>
            </TryItBar>
          </div>
          <div className='card' />
          <div className='card' />
        </div>
      </Body>
    </ViewerContainer>
  )
}

export default CenterAreaSkeleton
