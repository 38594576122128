import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  top: 0px;
  left: 0px;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
`
