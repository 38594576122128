import styled from 'styled-components'

export const RightBar = styled.div`
  height: 100%;
  display: grid;
  grid-column-start: 4;
  grid-column-end: 6;
  grid-template-columns: 40px auto;
  gap: 0rem;
  background-color: #3b4e5c;
  transition: 200ms;
`

export const Icon = styled.img`
  grid-row-start: 2;
  width: 50%;
  justify-self: center;
  cursor: pointer;
`

export const CommentsSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3b4e5c;
  justify-content: space-between;
  padding-bottom: 30px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 10%;
`

export const Button = styled.button`
  background-color: #949494;
  border-radius: 5px;
  height: 45%;
  width: 75%;
  border: 0px solid;
`

export const VersionContainer = styled.div`
  display: grid;
  grid-template-columns: 55px auto;
  grid-template-rows: 36px minmax(50px, max-content);
  align-items: center;
`

export const VersionNumber = styled.span`
  color: #949494;
  border-radius: 5px;
  border: solid 1px #949494;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`

export const VersionText = styled.span`
  align-self: baseline;
`

export const VerticalRule = styled.hr`
  background-color: #949494;
  height: 200px;
  width: 1px;
  border: 0px;
  margin-left: 15px;
`

export const ExpandBar = styled.div`
  display: grid;
  grid-template-rows: 2.3% auto;
  background-color: #1e2b34;
  transition: 200ms;
`

export const CommentsContainer = styled.div`
  margin-top: 5vh;
  margin-left: 5px;
  padding-top: 5px;
  max-height: 75vh;
`

export const TextSkeleton = styled.div`
  background-color: #949494;
  height: 16px;
  margin-bottom: 2px;
  max-width: 75%;
  border-radius: 3px;
`

export const TextSkeletonSmall = styled.div`
  background-color: #949494;
  height: 10px;
  margin: 6px 0 2px;
  max-width: 75%;
  border-radius: 3px;
`

export const InputSKeleton = styled.div`
  margin-top: 5px;
  height: 100px;
  background-color: #949494;
  position: absolute;
  width: 11%;
  border-radius: 3px;
`
