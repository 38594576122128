import styled from 'styled-components'

export const ViewerContainer = styled.div`
  display: grid;
  grid-column-start: 3;
  grid-column-end: 4;
`

export const Arrow = styled.div`
  font-weight: 900;
  font-size: 30px;
  color: #1e2b34;
`

export const TextStyleSmall = styled.span`
  margin: 0 0 0px 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
  color: #1f2f3b;

  @media (max-width: 800px) {
    display: none;
  }

  @media (max-width: 1150px) and (min-width: 800px) {
    font-size: 10px;
  }
`

export const InfoBar = styled.div`
  height: 30px;
  background-color: #1f2f3b;
  border-radius: 20px;
  max-width: 200px;
  min-width: 55px;
  width: 100%;
`

export const TryItBar = styled.div`
  width: fit-content;
  height: 30px;
  padding: 10px 10px 10px 10.2px;
  border-radius: 17px;
  background-color: #1f2f3b;
  display: flex;
  align-items: center;
`
export const Icon3D = styled.div`
  height: 14px;
  width: 14px;
`

export const Body = styled.div`
  display: flex;
  justify-content: center;

  .grid {
    width: 100%;
    max-height: 100vh;
    display: grid;

    grid-template-areas:
      'revision    revision    revision    revision    revision'
      'prevArrow   infoBar     infoBar     infoBar     nextArrow'
      'assetViewer assetViewer assetViewer assetViewer assetViewer'
      'qrCode      qrCode      NA          NA          NA'
      'tryItBar    tryItBar    carouselBar zoomBar     zoomBar';

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      overflow: hidden;

      img {
        max-width: 95%;
        max-height: 100%;
      }
    }
    .card:nth-child(1) {
      grid-area: revision;
    }
    .card:nth-child(2) {
      grid-area: prevArrow;
    }
    .card:nth-child(3) {
      grid-area: infoBar;
    }
    .card:nth-child(4) {
      grid-area: nextArrow;
    }
    .card:nth-child(5) {
      grid-area: assetViewer;
    }
    .card:nth-child(6) {
      grid-area: tryItBar;
    }
    .card:nth-child(7) {
      grid-area: qrCode;
    }
    .card:nth-child(8) {
      grid-area: carouselBar;
    }
    .card:nth-child(9) {
      grid-area: zoomBar;
    }

    grid-template-rows: 1% 9% 79% 1% 5%;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
`
