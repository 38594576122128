import React from 'react'
import { Container } from './styles'

import LeftBarSkeleton from './LeftBar'
import RightBarSkeleton from './RightBar'
import CenterAreaSkeleton from './CenterArea'

const ReviewPageSkeleton: React.FC = () => {
  return (
    <Container id='grid'>
      <LeftBarSkeleton />
      <CenterAreaSkeleton />
      <RightBarSkeleton />
    </Container>
  )
}

export default ReviewPageSkeleton
