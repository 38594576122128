import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ProductContext } from '../../contexts/ProductContext'
import { VersionContext } from '../../contexts/VersionContext'
import { Container, VersionContainer, VersionNumber, VersionText, VerticalRule } from './styles'
import CommentSection from '../CommentSection'

const getOrdinal = (n: number) => {
  const lastDigit = n % 10
  if (lastDigit === 1 && n !== 11) return 'first'
  if (lastDigit === 2 && n !== 12) return 'second'
  if (lastDigit === 3 && n !== 13) return 'third'
  return 'other'
}

const formatDate = (s: string) => {
  const d = new Date(s)
  let day = String(d.getDate())
  let month = String(d.getMonth() + 1)
  const year = String(d.getFullYear()).substr(2)

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return `${day}/${month}/${year}`
}

const VersionSelector: React.FC = () => {
  const { t } = useTranslation(['review'])
  const { product } = useContext(ProductContext)
  const { versionIndex, setVersionIndex } = useContext(VersionContext)

  const versions = product?.Versions || []

  return (
    <Container>
      {versions.map((version, index) => {
        const versionNumber = versions.length - index
        const ordinal = getOrdinal(versionNumber)
        const handleClick = () => setVersionIndex(index)
        const usdzSize = version.Assets?.find((e) => e.type === 'USDZ')?.size
        return (
          <VersionContainer key={version.id}>
            <VersionNumber selected={index === versionIndex} onClick={handleClick}>
              {versionNumber}
              <sup>{t(`versions.${ordinal}`)}</sup>
            </VersionNumber>
            <VersionText onClick={handleClick}>
              {versionNumber}
              <sup>{t(`versions.${ordinal}`)}</sup> {t('versions.revision')}
              {usdzSize ? ` (${t('versions.USDZ')} ${usdzSize})` : null}
              <p>
                {t('versions.created')}: {formatDate(version.createdAt)}
              </p>
            </VersionText>
            {index !== versions.length - 1 ? <VerticalRule /> : <span />}
            {index === versionIndex ? <CommentSection /> : null}
          </VersionContainer>
        )
      })}
    </Container>
  )
}

export default VersionSelector
