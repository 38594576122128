import styled from 'styled-components'

export const Button = styled.button`
  font-family: 'futura-pt';
  background-color: #1f2f3b;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  border: 0px solid;
  margin-left: 40px;
`
