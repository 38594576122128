import styled from 'styled-components'

export const LeftBar = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: auto 40px;
  grid-template-rows: 100%;
  gap: 0rem;
  background-color: #3b4e5c;
`
export const ExpandBar = styled.div`
  display: grid;
  grid-template-rows: 2.3% auto;
  background-color: #1e2b34;
`

export const Grid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: max-content auto;
  column-gap: 20px;
  row-gap: 20px;
`

export const Gallery = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 0.9fr));
  grid-auto-rows: 120px;
`

export const Image = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 5px;
  place-self: center;
  background-color: lightgray;
`

export const MainImage = styled.div`
  max-width: 650px;
  width: 90%;
  height: 40vh;
  margin-top: 10px;
  border-radius: 5px;
  justify-self: center;
  background-color: lightgray;
`
