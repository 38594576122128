import React from 'react'

import {
  RightBar,
  Icon,
  CommentsSection,
  ButtonContainer,
  Button,
  VersionContainer,
  VersionNumber,
  VersionText,
  VerticalRule,
  ExpandBar,
  CommentsContainer,
  TextSkeleton,
  InputSKeleton,
  TextSkeletonSmall
} from './styles'

const RightBarSkeleton: React.FC = () => {
  return (
    <RightBar>
      <ExpandBar>
        <Icon />
      </ExpandBar>
      <CommentsSection>
        <CommentsContainer>
          <VersionContainer>
            <VersionNumber />
            <VersionText>
              <TextSkeleton />
              <TextSkeleton />
              <TextSkeletonSmall />
              <InputSKeleton />
            </VersionText>
            <VerticalRule />
          </VersionContainer>
        </CommentsContainer>
        <ButtonContainer>
          <Button />
          <Button />
        </ButtonContainer>
      </CommentsSection>
    </RightBar>
  )
}

export default RightBarSkeleton
