import styled from 'styled-components'

interface ButtonProps {
  approve: boolean
}

export const Button = styled.button<ButtonProps>`
  background-color: #8ec396;
  border-radius: 5px;
  height: 45%;
  width: 75%;
  border: 0px solid;
  span {
    color: white;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
  }

  ${(props) => (props.approve ? 'background-color: #8ec396' : 'background-color:  #d94e4e')};
`
